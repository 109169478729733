* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

*::-webkit-scrollbar {
    display: none;
}

.page-wrap-row {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.report-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 10px;

    .report-object {
        display: flex;
        flex-direction: column;
        text-align: flex-start;
        border-radius: 5px;
        margin: 10px;
        width: 350px;
        height: 350px;
        background-color: #060a24;
        color: white;

        .show-report {
            width: 100px;
            font-size: 14px;
            font-size: medium;
            align-self: center;
            margin-top: auto;
            margin-bottom: 10px;
            border: none;
            background-color: #060a24;
            color: white;

            hr {
                margin-left: 15px;
                color: white;
                width: 80px;
            }
        }

        .show-report:hover {
            cursor: pointer;
        }

        h5,
        p {
            color: white;
            margin: 10px;
        }
    }
}

.return-button {
    display: block;
    width: 85px !important;
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.popup-content {
    --webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}