body {
    margin: 0;
    /* Reset default margin */
}

iframe {
    display: block;
    /* iframes are inline by default */
    background: white;
    border: none;
    /* Reset default border */
    height: 100vh;
    /* Viewport-relative units */
    width: 100vw;
    margin-top: 15px;
    margin-left: 165px;

    .container {
        background-color: white;
    }

}

@media (max-width: 800px) {
    iframe {
        margin-top: 10px;
        margin-left: 30px;
    }
}