.registration-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    border: 2px solid #eb1e1beb;
    border-radius: 10px;
    margin-bottom: 25px;

    .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        color: black !important;
        text-align: center;
    }
}

.registration-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 55px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
    padding: 0 25px;

    .forum-step {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
        height: 30px;
        border-bottom: 3px solid rgba(0, 0, 0, 0.2);

        p {
            color: lightgray;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
        }
    }

    .active {
        border-bottom: 3px solid #D63230;
        cursor: pointer;
        transition: .2s;

        p {
            color: #D63230;
        }
    }

    .active:hover {
        filter: brightness(60%);
    }
}

.registration-body {
    // min-height: 500px;
    box-sizing: border-box;
    padding: 0 25px;

    .login-register-info-wrapper {
        // padding: 0,0, 5px,8px;

        p {
            margin-left: 8px;
            margin-bottom: 15px;
            color: gray;
        }
    }

    .validationError {
        position: relative;
        border: 2px solid #eb1e1beb;
        border-radius: 10px;

        .password-toggle {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 41px;
            position: absolute;
            top: 0;
            right: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            cursor: pointer;

            i {
                color: black;
            }
        }

        .password-toggle:hover {
            background-color: rgba(43, 43, 43, 0.199);
        }
    }

    .validationErrorText {
        color: #eb1e1beb;
    }

    .validationSuccess {
        position: relative;
        border: 2px solid #aeec1eeb;
        border-radius: 10px;
    }

    .input-color {
        position: relative;
        z-index: 0;
        padding: 2px;

        textarea {
            height: 100%;
        }

        .password-toggle {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 41px;
            position: absolute;
            top: 0;
            right: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            cursor: pointer;

            i {
                color: black;
            }
        }

        .password-toggle:hover {
            background-color: rgba(43, 43, 43, 0.199);
        }
    }


    #logo-media-id {
        color: transparent;
        width: 100%;
        max-width: 225px;
        padding: 0;
        height: 40px;
    }

    #logo-media-id::-webkit-file-upload-button {
        visibility: hidden;
    }

    #logo-media-id::before {
        text-align: center;
        line-height: 40px;
        content: 'Upload uw logo';
        color: black;
        display: inline-block;
        background: white;
        border-radius: 10px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
        box-sizing: border-box;
        min-height: 30px;
        width: 100%;
        transition: .2s;
    }

    input[type=file]::file-selector-button {
        text-align: center;
        line-height: 40px;
        content: 'Upload uw logo';
        color: black;
        display: inline-block;
        background: white;
        border-radius: 10px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
        box-sizing: border-box;
        min-height: 30px;
        width: 100%;
        transition: .2s;
        border: none;
    }

    input[type=file]::file-selector-button:hover {
        filter: brightness(80%);
    }

    #logo-media-id:hover::before {
        filter: brightness(80%);
    }

    #logo-media-id:active {
        outline: 0;
    }

    #logo-media-id:active::before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    .terms-wrapper {
        margin-bottom: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        #terms-btn {
            width: fit-content;
        }
    }

    .form-step-2 {
        .input-section-row:nth-child(4) {
            margin: 0 !important;
        }
    }

    .form-step-3 {
        #find-us-text {
            border-radius: 10px;
            border: none;
            box-sizing: border-box;
            padding: 10px;
            width: 100%;
            min-height: 163px;
            border-radius: 10px;
            resize: none;
            outline: none;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
        }

        .registration-error-message {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 50px;
            border: 2px solid #eb1e1beb;
            border-radius: 10px;
            margin-bottom: 25px;

            .text-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
                color: black !important;
                text-align: center;
            }
        }

        .show-error-message {
            display: block;
        }

        .hide-error-message {
            display: none;
        }

        #find-us-text::after {
            border: none;
            outline: none;
        }

        .input-color {
            width: 100%;
            height: 180px;
        }

        .scope-btn-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: flex-start;

            .scope {
                position: relative;
                z-index: 0;
                padding: 1px;
                width: 107px;
                min-height: 40px;
                margin: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 5px;
                transition: .2s;

                p {
                    margin: 0;
                    padding: 0;
                    color: black;
                    -webkit-touch-callout: none;
                    /* iOS Safari */
                    -webkit-user-select: none;
                    /* Safari */
                    -khtml-user-select: none;
                    /* Konqueror HTML */
                    -moz-user-select: none;
                    /* Old versions of Firefox */
                    -ms-user-select: none;
                    /* Internet Explorer/Edge */
                    user-select: none;
                    font-size: 13px;
                }
            }

            .scope:hover {
                background-color: rgba(43, 43, 43, 0.199);
            }

            .scope::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 5px;
                padding: 1px;
                background: linear-gradient(45deg, #D63230EB, #0E122DEB);
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                z-index: -1;
            }

            .active {
                background: rgb(2, 0, 36);
                background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(214, 50, 48, 1) 0%, rgba(14, 18, 45, 1) 100%, rgba(0, 212, 255, 1) 100%);

                p {
                    color: white;
                }
            }
        }

    }

    .input-color::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 1px;
        background: linear-gradient(45deg, #D63230EB, #0E122DEB);
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: -1;
    }

    input {
        position: relative;
        z-index: 0;
        min-height: 40px;
        border-radius: 10px;
        outline: none;
        color: black;
        border-radius: 10px;
        /*1*/
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0 12px;
    }

    label {
        color: gray;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        padding: 0 0 5px 8px;
    }

    a {
        color: gray;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
    }

    .input-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        p {
            color: #eb1e1beb;
            font-family: "Lato", sans-serif;
            font-weight: 400;
            padding: 0 0 5px 8px;
        }

    }

    .input-section:nth-child(1) {
        margin-top: 30px;
    }

    .input-section-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 25px;

        input {
            // width: 100%;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 225px;

            .fa-circle-question {
                color: black;
                cursor: pointer;
                transition: .2s;
            }

            .fa-circle-question:hover {
                // filter: brightness(80%);
                color: gray;
                transition: .2s;
            }

            p {
                color: #eb1e1beb;
                font-family: "Lato", sans-serif;
                font-weight: 400;
                padding: 0 0 5px 8px;
            }
        }

        @media screen and (max-width: 570px) {
            .input-wrapper {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                max-width: 600px;
            }

            .input-wrapper:nth-child(1) {
                margin-bottom: 25px;
            }
        }
    }
}

.registration-footer {
    min-height: 70px;
    box-sizing: border-box;
    padding: 0 25px;

    .next-step-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 100%;
        max-width: 230px;
        min-height: 60px;
        border: none;
        cursor: pointer;
        background: rgb(2, 0, 36);
        background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(214, 50, 48, 1) 0%, rgba(14, 18, 45, 1) 100%, rgba(0, 212, 255, 1) 100%);
        color: white;
        transition: .2s;

        svg {
            margin-left: 10px;
        }
    }

    .next-step-btn:hover {
        filter: brightness(80%);
    }

}

.form-step-1 {
    display: none;
}

.form-step-2 {
    // display: none;
}

.form-step-3 {
    display: none;
}

.login-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    border: 2px solid #eb1e1beb;
    border-radius: 10px;
    margin-bottom: 25px;

    p {
        color: black;
        text-align: center;
    }
}

.hide-error-message {
    display: none;
}

.loading {
    button {
        background: black !important;
        color: rgb(140, 140, 140) !important;
        cursor: not-allowed;
        pointer-events: none;
        user-select: none;
    }

    svg:nth-child(1) {
        display: none;
    }

    svg:nth-child(2) {
        position: absolute;
        width: 40px;
        color: white;
        background-color: white;
        width: 55px;
    }
}

.notLoading {
    svg:nth-child(2) {
        display: none !important;
    }
}