@font-face {
    font-family: Nunito-regular;
    src: url('../../../fonts/Nunito-Regular.ttf');
}

@font-face {
    font-family: Nunito-Medium;
    src: url('../../../fonts/Nunito-Medium.ttf');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../../fonts/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../../fonts/Nunito-Bold.ttf');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../../fonts/Nunito-ExtraBold.ttf');
}

@font-face {
    font-family: Nunito-Black;
    src: url('../../../fonts/Nunito-Black.ttf');
}

@media (min-width: 140px) {
    .css-tlfecz-indicatorContainer {
        display: none !important;
    }

    .select-report-container-main {
        height: 100%;
    }

    .modal-search {
        padding: 1vw;
    }

    .button {
        background-color: #060a24;
        color: #ffffff;
        border: none;
        border-radius: 0.3vw;
        font-size: .7vw;
        margin-left: .5vw;
        width: 4vw;
        height: 2vw;
    }

    .select-inspectie {
        padding: 2.5vw;
        margin-top: 4vw;
        margin-left: 12vw;
        overflow-y: scroll;
        overflow-x: hidden;
        color: #060a24;

        .inspectie-select {
            width: 20vw;
            font-size: .8vw;
            position: absolute;
            overflow: visible;
        }

        hr {
            padding: .08vw;
            color: rgba($color: #060a24, $alpha: 0.6);
            width: 80vw;
        }


        h1 {
            font-family: Nunito-ExtraBold;
            color: #060a24;
            font-size: 1.6vw;
        }

        .hr {
            margin-top: 8vw;
        }

        .hr1 {
            margin-top: 4vw;
        }

        .hr2 {
            margin-top: 2vw;
        }

        #react-select-3-listbox {
            width: 20vw;
            background-color: #ffffff;
        }


        .select__menu {
            width: 20vw;
            font-size: .8vw;
        }

        .select__control {
            padding: .2vw;
            margin-top: 1vw;
            width: 20vw;
            border-radius: .5vw;
            font-size: .8vw;
            border: rgba($color: #060a24, $alpha: 0.1) solid .15vw;

            .select__indicator-separator {
                display: none;
            }

            .select__indicator {
                display: none;
            }
        }

        label {
            margin-top: 1vw;
            font-size: 1vw;
            margin-left: .3vw;
            margin-bottom: .8vw;
        }

        .show-rapportage-toevoegen {
            display: none;
        }

        .select-inspectie-row-title {
            display: flex;
            flex-direction: row;
            align-self: center;
            justify-self: center;
        }

        .inspectie-toevoegen-txt {
            font-family: Nunito-Black;
            font-size: 1vw;
            color: rgba($color: #060a24, $alpha: 0.6);
        }

        .selected-inspectie-row {
            display: flex;
            flex-direction: row;
            margin-top: 2.5vw;
            align-items: center;

            .selected-inspectie-info {
                display: flex;
                width: 250px;
                height: 200px;
                background-color: #ffffff;
                border-radius: .5vw;
                box-shadow: 0 0 1px rgba($color: #060a24, $alpha: 0.4);
                padding: .5vw;
                color: #060a24;
                justify-content: baseline;
                align-items: center;
                font-size: 16px;

                div {
                    padding: 1vw;

                    p {
                        font-family: Nunito-Bold;
                        color: rgba($color: #060a24, $alpha: 0.4);
                        padding-bottom: .6vw;
                    }
                }
            }

            .selected-inspectie-info-left {
                margin-left: 8vw;
                margin-top: -4.5vh;

                label {
                    font-size: 1vw;
                    font-family: Nunito-Bold;
                    color: #060a24;
                }

                img {
                    border-radius: 8px;
                    height: 175px;
                    width: 250px;
                }
            }
        }

        .rapportage-textarea {
            box-sizing: border-box;
            font-family: Nunito-regular;
            margin-top: .3vw;
            margin-bottom: 1vw;
            padding: .5vw;
            width: 10vw;
            height: 15vw;
            border-radius: .5vw;
            border: rgba($color: #060a24, $alpha: 0.2) solid 1px;
            resize: none;
            font-size: .9vw;
        }

        .rapportage-textarea:last-child {
            margin-bottom: 0;
        }

        .rapportage-input-col {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            color: #060a24;
            padding-top: 1vw;
            margin-right: 2vw;

            .rapportage-textarea {
                height: 10vw;
            }

        }

        .css-319lph-ValueContainer {
            padding: .4vw;
        }


        .rapportage-input-row {
            display: flex;
            flex-wrap: wrap;
            color: #060a24;
            margin-top: 1vw;

            .rapportage-textarea-normen {
                box-sizing: border-box;
                font-family: Nunito-regular;
                margin-top: 1vw;
                margin-bottom: 1vw;
                padding: 1vw;
                width: 18vw;
                height: 6vw;
                border-radius: .6vw;
                border: rgba($color: #060a24, $alpha: 0.2) solid 1px;
                resize: none;
                font-size: .9vw;
            }


            .rapportage-input-left {
                width: 20vw;
                padding-top: 1.5vw;

                .rapportage-input {
                    margin-bottom: 2vw;
                    padding: 1vw;
                    border-radius: .8vw;
                    border: rgba($color: #060a24, $alpha: 0.2) solid 1px;
                }

                .rapportage-input:hover {
                    border: 1px solid#060a24;
                    box-shadow: 0 0 1px#060a24;
                    -moz-box-shadow: 0 0 1px#060a24;
                    -webkit-box-shadow: 0 0 1px#060a24;
                }

                .rapportage-input:focus {
                    border: 1px solid #060a24;
                    box-shadow: 0 0 1px#060a24;
                    -moz-box-shadow: 0 0 1px#060a24;
                    -webkit-box-shadow: 0 0 1px#060a24;
                }

                .css-1s2u09g-control {
                    border-radius: 10px;
                }

                .css-1okebmr-indicatorSeparator {
                    display: none;
                }

                .css-6j8wv5-Input {
                    padding: 10px;
                }
            }

            .rapportage-input-right {
                margin-left: 75px;
            }

        }
    }
}

@media (min-width: 140px) {
    .hr1 {
        width: 100% !important;
    }

    .submit-rapportage {
        font-size: 8px !important;
    }
}

@media (min-width: 400px) {
    .select-inspectie {
        margin-left: 40px !important;
    }

    .hr1 {
        width: 100% !important;
    }
}

@media only screen and (min-width: 400px) {
    .select-inspectie {
        margin-left: 40px !important;
    }

    .submit-rapportage {
        font-size: 12px !important;
    }

    .selected-inspectie-info {
        font-size: 16px !important;
    }
}

@media (max-width: 800px) {


    .selected-inspectie-info {
        width: 200px !important;
        height: 150px !important;
    }

    .selected-inspectie-info-left {
        img {
            height: 125px !important;
            width: 200px !important;
        }
    }

    .inspectie-text {
        font-size: 16px !important;
    }

    .select-inspectie {

        .strong-inspectie {

            font-size: 16px !important;
        }

        .label-inspectie {
            font-size: 16px !important;
        }

        .inspectie-header {
            font-size: 18px !important;
        }

        .strong-inspectie {
            font-size: 16px !important;
        }

        .rapportage-textarea {
            font-size: 16px !important;
        }

        .css-1s2u09g-control {
            font-size: 16px !important;
        }

        .form-control {
            font-size: 16px !important;
        }
    }
}

@media(min-width: 800px) {

    .inspectie-text {
        font-size: 16px !important;
    }

    .select-inspectie {
        .label-inspectie {
            font-size: 17px !important;
        }

        .inspectie-header {
            font-size: 22px !important;
        }

        .strong-inspectie {
            font-size: 17px !important;
        }

        .rapportage-textarea {
            font-size: 17px !important;
        }

        .form-control {
            font-size: 17px !important;
        }

        .css-1s2u09g-control {
            font-size: 17px !important;
        }
    }
}

@media (min-width: 800px) and (max-width: 980px) {
    .select-inspectie {
        margin-left: 165px !important;
    }
}

@media (min-width: 980px) {
    .select-inspectie {
        margin-left: 175px !important;
    }

}