* {
    -moz-user-select: none;
    /* firefox */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE*/
    user-select: none;
    /* Standard syntax */
}

p {
    margin-bottom: 0;
}

// nav {
//     width: 100%;
//     background-color: #060a24;
//     box-sizing: border-box;
//     padding: 0 25px;

//     .nav-content-wrapper {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         height: 4vw;
//     }
// }
@media (max-width: 800px) {
    .page-wrapper-teams {
        margin-left: 30px !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    h2 {
        margin-left: 1px !important;
    }

    .select-report-create {
        margin-right: 70px;
    }
}

.page-wrapper-teams {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    background-color: #f2f2f2;
    color: black;
    margin-left: 170px;
    padding: 45px 35px;
    font-family: Nunito-Light;
}

.searchBar-wrapper {
    position: relative;
    color: rgb(190, 190, 190);

    svg {
        position: absolute;
        margin: 9px 0px 0px 15px;
    }
}

.btn-danger {
    background-color: #d63230;
    box-shadow: 0px 4px #a22321;
    color: #fff;
    height: 38px;
    min-width: 180px;
    border: none;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    position: relative;
    float: right;
}

.swal2-title {
    color: #000 !important;
    opacity: 80%;
    font-size: 30px;
    font-weight: 600;
    margin: 5px 0;
    font-family: Nunito-light;
}

.swal2-html-container {
    color: #000 !important;
    opacity: 90%;
    font-size: 18px !important;
    font-weight: 600;
    margin: 5px 0;
    font-family: Nunito-light;
    line-height: 1.5 !important;
}

.btn-success,
.btn-success:active {
    background-color: #198754;
    box-shadow: 0px 4px #20764e;
    color: #fff;
    height: 38px;
    min-width: 180px;
    border: none;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    position: relative;
    float: right;
}

.teams-searchBar {
    width: 100% !important;
    min-height: 38px !important;
    margin-left: 10px;
    font-size: 16px;
    font-family: Nunito-Regular;
    text-align: start;
    padding-left: 30px;
    border: rgba($color: #060a24, $alpha: 0.2) solid 2px;
    border-radius: 5px;
}

.teams-searchBar::placeholder {
    overflow: visible;
}

.top-wrapper {
    margin-top: 40px;
    min-height: 65px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;

    h2 {
        color: #000;
        opacity: 80%;
        font-size: 30px;
        font-weight: 600;
        margin: 5px 0;
        font-family: Nunito-light;
    }

    .select-report-create {
        background-color: #d63230;
        color: #fff;
        height: 38px;
        min-width: 180px;
        box-shadow: 0px 4px #a22321;
        border: none;
        border-radius: 12px;
        text-align: center;
        text-decoration: none;
        position: relative;
        float: right;

        p {
            font-size: 16px !important;
            font-family: Nunito-Medium;
        }
    }

    .select-report-create:hover {
        margin-top: 5px;
        box-shadow: 0px 2px #a22321;
        transition: box-shadow 0.1s ease-in-out, margin-top 0.1s ease-in-out;
    }

}

.invite-button-wrapper {
    position: relative;
    color: #fff;

    svg {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
        transition: margin-top 0.1s ease-in-out;
    }
}

.invite-button-wrapper:hover {
    button {
        margin-top: 3px;
        box-shadow: 0px 4px #a22321;
    }
}

.team-wrapper {
    margin-top: 50px;

    .member {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 350px;
        text-align: center;
        box-shadow: 3px 3px 5px 0px #ccc;
        margin-bottom: 35px;
        position: relative;

        .member-top {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            background-color: #f4f4f4;
            height: 80px;
            padding: 10px;
        }

        hr {
            margin: 0;
            padding: 0;
            opacity: 10%;
        }

        .member-avatar-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: 35px;
            background-color: #fff;
            height: 126px;
            width: 126px;
            border-radius: 50%;
            outline: 1px solid rgba(0, 0, 0, 0.5);
            outline-offset: -4px;

            .member-avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: linear-gradient(red, black);
                height: 110px;
                width: 110px;
                border-radius: 50%;

                h2 {
                    color: #fff;
                    position: absolute;
                    top: 51%;
                    left: 49%;
                    transform: translate(-50%, -60%);
                    font-family: Nunito-Regular;
                    font-size: 44px;
                }
            }
        }

        .member-bottom {
            background-color: #fff;
            flex-grow: 1;
            padding-top: 100px;

            h2 {
                color: #000;
                opacity: 80%;
                font-size: 24px;
                font-weight: 600;
            }

            h3 {
                color: #000;
                opacity: 50%;
                font-size: 18px;
                font-weight: 100;
            }
        }
    }

    .invite-member {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 350px;
        text-align: center;
        margin-bottom: 35px;
        position: relative;
        background-color: #e3e3e3;
        border: 5px dashed rgba(0, 0, 0, 0.1);

        .invite-circle-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: 35px;
            background-color: #fff;
            height: 126px;
            width: 126px;
            border-radius: 50%;
            outline: 1px solid rgba(0, 0, 0, 0.5);
            outline-offset: -4px;

            .invite-circle {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: #dfdfdf;
                height: 110px;
                width: 110px;
                border-radius: 50%;

                svg {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    opacity: 0.3;
                }
            }

            .invite-circle:hover {
                cursor: pointer;
            }
        }

        .invite-member-text {
            margin-top: 160px;
            margin-bottom: 50px;
            padding: 20px;

            h2 {
                color: #000;
                opacity: 80%;
                font-size: 24px;
                font-weight: 600;

            }

            h3 {
                color: #000;
                opacity: 50%;
                font-size: 18px;
                font-weight: 100;
            }
        }
    }
}

.border-radius-modal>div {
    border-radius: 20px !important;
    box-shadow: 3px 3px 1px 1px rgba($color: #000000, $alpha: 0.1);
}

.modal-wrapper {
    font-family: Nunito-SemiBold;
    padding: 80px 50px;

    h2 {
        color: #000;
        opacity: 80%;
        font-size: 24px;
    }

    h4 {
        color: #000;
        opacity: 50%;
        font-size: 18px;
    }

    .form-control {
        width: 100%;
        margin-top: 30px;
    }

    .button-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
        color: #fff;
        height: 55px;
        margin-top: 30px;

        svg {
            width: 18px;
            margin-right: 10px;
            margin-top: -3px;
            margin-left: -10px;
        }

        .send-invite {
            background-color: #d63230;
            color: #fff;
            font-size: 17px;
            font-family: Nunito-Medium;
            height: 100%;
            width: 250px;
            box-shadow: 0px 7px #a22321;
            border: none;
            border-radius: 12px;
            position: relative;
            transition: box-shadow 0.1s ease-in-out, margin-top 0.1s ease-in-out;
        }

        .send-invite:hover {
            margin-top: 3px;
            box-shadow: 0px 4px #a22321;
        }

        .cancel-invite {
            background-color: transparent;
            border: none;
            color: #d63230;
            width: 150px;
            transition: color 0.1s ease-in-out;
        }

        .cancel-invite:hover {
            color: #000;
        }
    }

    .form-control:focus {
        border-color: #000000;
        box-shadow: none;
    }


}

.close-invite {
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    background-color: #fff;
    border: none;
    box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.2);

    svg {
        opacity: 0.5;
        rotate: 45deg;
        transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
}

.close-invite:hover {
    svg {
        opacity: 1;
        color: #d63230;
    }
}

@media only screen and (max-width: 768px) {
    .top-wrapper {
        flex-direction: column;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 576px) {
    .top-wrapper {
        h2 {
            font-size: 24px;
        }
    }

    .invite-button-wrapper {
        width: 100%;

        button {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 360px) {
    nav {
        font-size: 12px;

        strong {
            margin-left: 5px;
        }

        p {
            margin-left: 5px;
        }
    }
}

@font-face {
    font-family: Nunito-xLight;
    src: url('../../fonts/Nunito-ExtraLight.ttf');
}

@font-face {
    font-family: Nunito-Light;
    src: url('../../fonts/Nunito-Light.ttf');
}

@font-face {
    font-family: Nunito-Regular;
    src: url('../../fonts/Nunito-Regular.ttf');
}

@font-face {
    font-family: Nunito-Medium;
    src: url('../../fonts/Nunito-Medium.ttf');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../fonts/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../fonts/Nunito-Bold.ttf');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../fonts/Nunito-ExtraBold.ttf');
}

@font-face {
    font-family: Nunito-Black;
    src: url('../../fonts/Nunito-Black.ttf');
}