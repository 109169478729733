.login-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    border: 2px solid #eb1e1beb;
    border-radius: 10px;
    margin-bottom: 25px;

    p {
        color: black;
        text-align: center;
    }
}

.login-lbl {
    font-size: 20px !important;
}

.login-nav-link {
    font-size: 16px !important;
}

.login-svg {
    width: 10px !important;
    height: 26px !important;
}

.left-title {
    strong {
        font-size: 48px !important;
    }

    h1 {
        font-size: 48px !important;
    }
}