.uitgangspunten-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    textarea {
        width: 40%;
        height: 80px;
    }
}

.datetime-width {
    width: 80%;
}

h1 {
    font-size: 23px;
}




.submit-rapportage {
    display: flex;
    background-color: #d63230;
    color: #fff;
    margin-top: -18px !important;
    height: 38px;
    width: 120px;
    box-shadow: 0px 4px #a22321;
    border: none;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    position: relative;
    font-size: 16px !important;
    padding-top: 8px !important;
    padding-left: 16px !important;
}

.submit-rapportage-edit {
    display: flex;
    background-color: #d63230;
    color: #fff;
    margin-top: -18px !important;
    height: 38px;
    width: 120px;
    box-shadow: 0px 4px #a22321;
    border: none;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    position: relative;
    font-size: 16px !important;
    padding-top: 8px !important;
    padding-left: 16px !important;
}

.modal {
    display: flex;
    position: fixed;
    height: 80vh;
    border-top: 1px solid #060a24;
}

.popup-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: white;
    width: 90%;
    font-size: 22px;

    .modal-search {
        width: 220px !important;
        height: 40px !important;
        font-size: 18px !important;
        font-family: Nunito-Regular;
        border: rgba($color: #060a24, $alpha: 0.2) solid 1px;
        border-radius: 5px;
        margin-bottom: 5px;
    }

    .text-label {
        font-size: 20px !important;
    }

    .close {
        width: 5vw;
        float: right;
        font-size: 1vw;
        color: #060a24;
        border-radius: .3vw;
        text-align: center;
    }

    button {
        text-align: start;
        border: none;
        background-color: transparent;
    }
}