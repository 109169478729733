@font-face {
    font-family: Nunito-regular;
    src: url('../../../fonts/Nunito-Regular.ttf');
}

@font-face {
    font-family: Nunito-Medium;
    src: url('../../../fonts/Nunito-Medium.ttf');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../../fonts/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../../fonts/Nunito-Bold.ttf');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../../fonts/Nunito-ExtraBold.ttf');
}

@font-face {
    font-family: Nunito-Black;
    src: url('../../../fonts/Nunito-Black.ttf');
}


.select-customer-container-main {
    height: 100%;
}

.button {
    background-color: #060a24;
    color: #ffffff;
    border: none;
    border-radius: 0.3vw;
    font-size: .7vw;
    margin-left: .5vw;
    width: 4vw;
    height: 2vw;
}

.create-customer {
    padding: 2.5vw;
    margin-top: 4vw;
    margin-left: 12vw;
    overflow-y: scroll;
    overflow-x: hidden;
    color: #060a24;
    box-sizing: border-box;
    padding: 0 25px;

    hr {
        padding: .08vw;
        color: rgba($color: #060a24, $alpha: 0.6);
        width: 80vw;
    }


    h1 {
        font-family: Nunito-ExtraBold;
        color: #060a24;
        font-size: 1.6vw;
    }

    .hr {
        margin-top: 8vw;
    }

    .hr1 {
        margin-top: 4vw;
    }

    .hr2 {
        margin-top: 2vw;
    }

    label {
        margin-top: 1vw;
        font-size: 1vw;
        margin-left: .3vw;
        margin-bottom: .8vw;
    }

    .show-customer-toevoegen {
        display: none;
    }

    .validationError {
        position: relative;
        border: 2px solid #eb1e1beb;
        border-radius: 10px;
    }

    .validationSuccess {
        position: relative;
        border: 2px solid #aeec1eeb;
        border-radius: 10px;
    }

    .validationErrorText {
        color: #eb1e1beb;
    }

    .input-color {
        position: relative;
        z-index: 0;
        padding: 2px;
    }

    .customer-input {
        box-sizing: border-box;
        font-family: Nunito-regular;
        margin-top: .3vw;
        margin-bottom: 1vw;
        padding: .5vw;
        width: 10vw;
        height: 15vw;
        border-radius: .5vw;
        border: rgba($color: #060a24, $alpha: 0.2) solid 1px;
        resize: none;
        font-size: .9vw;
        position: relative;
        border-radius: 10px;
    }

    #customer-media-id {
        color: transparent;
        width: 100%;
        padding: 0;
        height: 40px;
    }

    #customer-media-id::-webkit-file-upload-button {
        visibility: hidden;
    }

    #customer-media-id::before {
        text-align: center;
        line-height: 40px;
        content: 'Upload uw Objecten Image';
        color: black;
        display: inline-block;
        background: white;
        border-radius: 10px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
        box-sizing: border-box;
        min-height: 30px;
        width: 100%;
        transition: .2s;
    }

    input[type=file]::file-selector-button {
        text-align: center;
        line-height: 40px;
        content: 'Voeg een foto toe';
        color: black;
        display: inline-block;
        background: white;
        border-radius: 10px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
        box-sizing: border-box;
        min-height: 30px;
        width: 100%;
        transition: .2s;
        border: none;
    }

    input[type=file]::file-selector-button:hover {
        filter: brightness(80%);
    }

    #customer-media-id:hover::before {
        filter: brightness(80%);
    }

    #customer-media-id:active {
        outline: 0;
    }

    #customer-media-id:active::before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    .input-color::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 1px;
        background: #060a2433;
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: -1;
    }

    input {
        position: relative;
        z-index: 0;
        min-height: 40px;
        border-radius: 10px;
        outline: none;
        color: black;
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0 12px;
    }


    .customer-input-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        p {
            color: #eb1e1beb;
            font-family: "Lato", sans-serif;
            font-weight: 400;
            padding: 0 0 5px 8px;
        }

    }

    .customer-input-section:nth-child(1) {
        margin-top: 30px;
    }

    .object-wrapper {
        padding-left: 30%;
        padding-right: 30%;

        .new-object {
            display: flex;
            flex-direction: column;
            text-align: center;
            position: relative;
            background-color: #e3e3e3;
            border: 5px dashed rgba(0, 0, 0, 0.1);

            svg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                opacity: 0.3;
            }

            .new-object-text {
                margin-top: 60px;

                h2 {
                    color: #000;
                    opacity: 80%;
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
    }


    .input-section-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 25px;

        .input-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 49.5%;

            .fa-circle-question {
                color: black;
                cursor: pointer;
                transition: .2s;
            }

            .fa-circle-question:hover {
                // filter: brightness(80%);
                color: gray;
                transition: .2s;
            }

            p {
                color: #eb1e1beb;
                font-family: "Lato", sans-serif;
                font-weight: 400;
                padding: 0 0 5px 8px;
            }
        }

        .input-wrapper-image {
            display: flex;
            flex-direction: column;
            width: 25%;
            max-width: 49.5%;
        }

        @media screen and (max-width: 570px) {
            .input-wrapper {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                max-width: 600px;
            }

            .input-wrapper-image {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                max-width: 600px;
            }

            .input-wrapper:nth-child(1) {
                margin-bottom: 25px;
            }
        }
    }

}

@media (min-width: 140px) {
    .hr1 {
        width: 100% !important;
    }

    .submit-customer {
        font-size: 8px !important;
    }
}

@media (min-width: 400px) {
    .create-customer {
        margin-left: 40px !important;
    }

    .hr1 {
        width: 100% !important;
    }
}

@media only screen and (min-width: 400px) {
    .create-customer {
        margin-left: 40px !important;
    }

    .submit-customer {
        font-size: 12px !important;
    }
}

@media (max-width: 800px) {

    .create-customer {

        .strong-customer {

            font-size: 16px !important;
        }

        .label-customer {
            font-size: 16px !important;
        }

        .customer-header {
            font-size: 18px !important;
        }

        .strong-customer {
            font-size: 16px !important;
        }

        .customer-textarea {
            font-size: 16px !important;
        }

        .customer-input {
            font-size: 16px !important;
        }

        .css-1s2u09g-control {
            font-size: 16px !important;
        }

        .form-control {
            font-size: 16px !important;
        }
    }
}

@media(min-width: 800px) {

    .create-customer {
        .label-customer {
            font-size: 17px !important;
        }

        .customer-header {
            font-size: 22px !important;
        }

        .strong-customer {
            font-size: 17px !important;
        }

        .customer-textarea {
            font-size: 17px !important;
        }

        .customer-input {
            font-size: 17px !important;
        }

        .form-control {
            font-size: 17px !important;
        }

        .css-1s2u09g-control {
            font-size: 17px !important;
        }
    }
}

@media (min-width: 800px) and (max-width: 980px) {
    .create-customer {
        margin-left: 165px !important;
    }
}

@media (min-width: 980px) {
    .create-customer {
        margin-left: 175px !important;
    }

}