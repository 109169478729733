@font-face {
    font-family: Nunito-regular;
    src: url('../../../fonts/Nunito-Regular.ttf');
}

@font-face {
    font-family: Nunito-Medium;
    src: url('../../../fonts/Nunito-Medium.ttf');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../../fonts/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../../fonts/Nunito-Bold.ttf');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../../fonts/Nunito-ExtraBold.ttf');
}

@font-face {
    font-family: Nunito-Black;
    src: url('../../../fonts/Nunito-Black.ttf');
}

.select-report-container-main {
    width: 100%;
    overflow-x: hidden;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.css-tlfecz-indicatorContainer {
    display: none;
}

@media (min-width: 140px) {
    .select-report-container-main {
        .select-report-container {
            display: flex;
            flex-direction: column;
            color: #060a24;
            padding-left: 30px;
            width: 100%;
            padding-right: 4px;
            margin-top: 30px;

            .report-title {
                color: #000;
                opacity: 80%;
                font-size: 30px;
                font-weight: 600;
                margin-top: 15px;
                margin-left: 14px !important;
                font-size: 30px !important;
                font-family: Nunito-light;
            }

            .select-report-info {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-left: 6px;
                margin-bottom: 18px;

                .select-report-info-left {
                    display: flex;
                    flex-direction: row;
                    width: 95%;

                }

                .select-report-info-right {
                    display: flex;
                    flex-direction: row;
                    width: 25%;
                    margin-right: 20px;

                }

                .select-report-search {
                    width: 220px !important;
                    max-height: 38px !important;
                    font-size: 16px;
                    font-family: Nunito-Regular;
                    text-align: start;
                    border: rgba($color: #060a24, $alpha: 0.2) solid 2px;
                    border-radius: 5px;
                }

                h1 {
                    font-family: Nunito-Bold;
                }

                .css-1hb7zxy-IndicatorsContainer {
                    width: 0;
                }

                .css-1s2u09g-control {
                    min-height: 36px !important;
                    width: 110px !important;
                    border-radius: 4px;
                    text-align: center;
                    text-decoration: none;
                    position: relative;
                    margin-right: 10px;
                    margin-top: 1px !important;

                    div {
                        color: #000;
                        font-size: 16px;
                    }

                    svg {
                        display: none;
                    }
                }

                .css-1pahdxg-control {
                    min-height: 36px !important;
                    width: 110px !important;
                    border-radius: 4px;
                    text-align: center;
                    text-decoration: none;
                    position: relative;
                    margin-right: 10px;
                    margin-top: 1px !important;

                    div {
                        color: #000;
                        font-size: 16px;
                    }

                    svg {
                        display: none;
                    }
                }

                #react-select-18-listbox {
                    font-size: 16px !important;
                }

                .select-report-create {
                    background-color: #d63230;
                    color: #fff;
                    height: 36px;
                    min-width: 110px;
                    box-shadow: 0px 4px #a22321;
                    border: none;
                    border-radius: 12px;
                    text-align: center;
                    text-decoration: none;
                    position: relative;

                    p {
                        margin-top: 4px;
                        font-size: 16px !important;
                        font-family: Nunito-Medium;
                    }
                }

                .select-report-create:hover {
                    margin-top: 2px;
                    box-shadow: 0px 2px #a22321;
                    transition: box-shadow 0.1s ease-in-out, margin-top 0.1s ease-in-out;
                }
            }
        }

        .select-report-container-1 {
            display: flex;
            flex-direction: column;
            position: relative;
            margin: auto;
            width: 95%;
            margin-top: 15px;

            p {
                font-family: Nunito-Medium;
                color: #060a24;
            }

            h1 {
                color: #060a24;
            }

            .select-report-column-names {
                display: flex;
                justify-content: space-around;
                margin-bottom: 3px;

                p {
                    width: 100%;
                    margin-left: 10px;
                    font-size: 6px;
                    font-family: Nunito-Bold;
                }
            }
        }

        .select-report-reports-obj {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: rgba($color: #060a24, $alpha: 0.05) 0px 0px 0px 1px;
            background-color: #ffffff;

            .show-report {
                text-decoration: none;
            }

            .select-report-row {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                border-bottom: 1px solid rgba($color: #060a24, $alpha: 0.1);
                height: 35px;

                .facircle {
                    overflow: visible;
                    margin-top: -1px;
                }

                p {
                    width: 100%;
                    margin-left: 10px;
                    font-size: 6px;
                    align-self: center;
                    color: #5e5e5e;
                }

            }
        }
    }
}

@media (min-width: 240px) {
    .select-report-container {
        margin-left: 8px;
    }
}

@media (min-width: 348px) {
    .select-report-container {
        margin-left: 10px;
    }

    .select-report-container-1 {
        p {
            font-size: 16px !important;
        }
    }
}

@media (min-width: 480px) {
    .select-report-container {
        margin-left: 10px !important;
    }

    .report-title {
        margin-left: 5px;
    }

    .select-report-info-left {
        margin-left: 10px;
    }

    .select-report-row {
        height: 40px !important;
    }

    .select-report-info {
        font-size: 12px !important;
    }
}

@media (max-width: 599px) {
    .select-report-info-left {
        .select-report-search {
            margin-left: -5px;
        }

        width: 51% !important;
    }
}

@media (min-width: 600px) {
    .select-report-container {
        margin-left: 10px !important;
        margin-top: 8px;
    }

    .select-report-info-right {
        width: 40% !important;
    }

    .report-title {
        margin-left: 10px;
    }

    .select-report-info-left {
        margin-left: 10px;
    }

    .select-report-search {
        margin-left: -5px !important;
    }

    .select-report-row {
        height: 40px !important;
    }
}

@media (min-width: 720px) {
    .select-report-container {
        margin-left: 10px !important;
        margin-top: 25px;
    }

    .report-title {
        margin-left: 12px;
    }

    .select-report-info-left {
        margin-left: 10px;
    }

    .select-report-info-right {
        width: 31% !important;
    }

    .select-report-row {
        height: 60px !important;
    }

    .select-report-info-left {
        width: 67% !important;
    }
}

@media (max-width: 799px) {

    .select-report-column-names {
        p {
            font-size: 16px !important;
        }
    }

    .select-report-container-1 {
        p {
            font-size: 16px !important;
        }
    }
}

@media (min-width: 800px) {

    .select-report-column-names {
        p {
            font-size: 16px !important;
        }
    }

    .select-report-container-1 {
        p {
            font-size: 17px !important;
        }
    }
}

@media (min-width: 800px) {

    .report-title {
        margin-left: -10px;
    }

    .select-report-container {
        margin-left: 135px !important;
        margin-top: 30px;
    }

    .select-report-info-right {
        width: 35% !important;
    }

    .report-title {
        padding-left: 25px !important;
        margin-bottom: 15px !important;
        margin-top: 42px !important;
    }

    .select-report-info-left {
        margin-left: 3px;
        width: 65.7% !important;
    }

    .select-report-row {
        height: 85px !important;
    }

    .select-report-search {
        margin-left: 8px !important;
        font-size: 20px !important;
    }

    .css-1hb7zxy-IndicatorsContainer {
        width: 0;
    }

}

@media (min-width: 840px) {
    .select-report-info-right {
        width: 33% !important;
    }
}

@media (min-width: 880px) {
    .select-report-info-right {
        width: 31% !important;
    }
}

@media (min-width: 910px) {
    .report-title {
        margin-left: -8px;
    }

    .select-report-info-left {
        margin-left: 3px;
        width: 67% !important;
    }
}


@media (min-width: 980px) {
    .report-title {
        margin-left: -1pxpx !important;
    }

    .select-report-container {
        margin-left: 150px !important;
    }

    .select-report-info-left {
        width: 70% !important;

        .select-report-search {
            margin-left: 10px !important;
        }
    }


    .report-title {
        margin-bottom: 15px !important;
    }

    .select-report-row {
        height: 85px !important;
    }

    .select-report-container-1 {
        p {
            margin-left: 20px !important;
        }
    }

}

@media (min-width: 1040px) {
    .select-report-info-left {
        width: 71% !important;
    }
}

@media (min-width: 1100px) {
    .select-report-info-left {
        width: 73% !important;

        .select-report-search {
            margin-left: 10px !important;
            font-size: 17px !important;
        }
    }
}

.select-report-info {
    min-height: 50px;
}

@media (min-width: 1200px) {
    .report-title {
        margin-left: 0px !important;
        margin-bottom: 25px !important;
    }

    .select-report-info-left {
        width: 100% !important;

        .select-report-search {
            margin-left: 16px !important;
        }
    }

    .select-report-info {
        .select-report-info-right {
            width: 30% !important;
        }

    }
}

@media(min-width: 1300px) {
    .select-report-info-left {
        width: 100% !important;
    }

    .select-report-info-right {
        width: 80% !important;
        margin: 0 !important;
    }
}

@media (min-width: 1400px) {

    .report-title {
        margin-left: 6px !important;
        margin-bottom: 25px !important;
    }

    .select-report-container {
        margin-left: 165px;
        margin-top: 35px;
    }

    .select-report-row {
        height: 85px !important;
    }

    .select-report-info-left {
        width: 110% !important;

        .select-report-search {
            margin-left: 22px !important;
        }
    }
}

@media (min-width: 1500px) {
    .select-report-info-left {
        width: 120% !important;

        .select-report-search {
            margin-left: 25px !important;
        }
    }
}

@media(min-width: 1600px) {
    .select-report-info-left {
        width: 130% !important;

        .select-report-search {
            margin-left: 25px !important;
        }
    }

    .report-title {
        margin-top: 58px !important;
        margin-left: 9px !important;
    }
}

@media (min-width: 1700px) {
    .select-report-info-left {
        width: 140% !important;

        .select-report-search {
            margin-left: 30px !important;
        }
    }
}

@media (min-width: 1800px) {
    .select-report-info-left {
        width: 150% !important;

        .select-report-search {
            margin-left: 30px !important;
        }
    }

    .report-title {
        margin-left: 20px !important;
        margin-bottom: 25px !important;
    }
}

@media (min-width: 1900px) {
    .select-report-info-left {
        width: 160% !important;

        .select-report-search {
            margin-left: 35px !important;
        }
    }

}

@media(min-width: 1980px) {

    .select-report-info-left {
        width: 165% !important;

        .select-report-search {
            margin-left: 35px !important;
        }
    }
}

@media(min-width: 2560px) {
    .report-title {
        margin-top: 100px !important;
    }

    .select-report-info-left {
        width: 220% !important;
    }
}