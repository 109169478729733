.member-menu {
    position: relative;
    text-align: center;

    & > svg{
        opacity: 50%;
        color: black;
        margin-right: 15px;
    }

    .dropdown-toggle {
      cursor: pointer;
      display: inline-block;
    }
  
    .dropdown-menu {
      position: absolute;
      left: 0;
      right: 0;
      transform: translate(-42%, 0);
      margin: 0 auto;
      z-index: 1;
      padding: 0;
      list-style: none;
      background-color: white;
      font-size: 14px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);

      svg {
        margin-bottom: 4px;
        opacity: 50%;
        color: black;
      }

      &:before {
        content: "";
        position: absolute;
        top: -15px;
        left: 50%;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent white transparent;
      }
  
      li {
        a {
          display: block;
          padding: 8px 10px;
          color: black;
          text-decoration: none;
          &:hover {
            background-color: #eee;
          }
          &:first-child:hover + .dropdown-menu:before{
            border-color: transparent transparent #eee transparent;
          }
        }
      }
    }
  
    &.is-open {
      .dropdown-menu {
        display: block;
      }
    }
  }
  
