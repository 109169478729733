.submit-customer {
    display: flex;
    background-color: #d63230;
    color: #fff;
    margin-top: -18px !important;
    height: 38px;
    width: 120px;
    box-shadow: 0px 4px #a22321;
    border: none;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    position: relative;
    font-size: 16px !important;
    padding-top: 8px !important;
    padding-left: 16px !important;
}

.remove-customer{
     display: flex;
     justify-content: end;
     padding-top: 25px;
}

.submit-customer-edit {
    display: flex;
    background-color: #d63230;
    color: #fff;
    margin-top: -18px !important;
    height: 38px;
    width: 120px;
    box-shadow: 0px 4px #a22321;
    border: none;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    position: relative;
    font-size: 16px !important;
    padding-top: 8px !important;
    padding-left: 16px !important;
}