@media (min-width: 140px) {
    .menu-container {
        z-index: 0;
        position: fixed;
        padding-top: 40px;
        height: 100vh;
        float: left;
        width: 30px;
        overflow-y: hidden;
        padding-right: 1vw;
        background-color: #ffffff;

        a {
            text-decoration: none;
        }

        .dashboard-menu-items {

            margin-top: 25px;

            .dashboard-menu-item.active {
                height: 30px;
                align-items: center;
                justify-content: baseline;
                color: white;
                border-radius: 0vw 4px 4px 0vw;
                background-image: linear-gradient(96.24deg,
                        #d63230 -9.75%,
                        #0e122d 108.87%);

                svg path {
                    fill: white;
                }
            }

            .dashboard-menu-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: baseline;
                color: black;
                height: 30px;
                font-size: 6px;
                padding: 5px;
                margin-bottom: 10px;

                svg {
                    width: 14px;
                    height: 14px;
                }

                p {
                    display: none;
                }
            }

            .dashboard-menu-item:hover {
                -webkit-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
                color: white;
                border-radius: 0vw 4px 4px 0vw;
                background-image: linear-gradient(96.24deg,
                        #d63230 -9.75%,
                        #0e122d 108.87%);

                svg path {
                    -webkit-transition: all 0.2s ease-in-out;
                    -moz-transition: all 0.2s ease-in-out;
                    -o-transition: all 0.2s ease-in-out;
                    transition: all 0.2s ease-in-out;
                    fill: white;
                }
            }
        }
    }
}

@media (min-width: 240px) {
    .menu-container {
        min-width: 30px;
    }
}

@media (min-width: 340px) {
    .menu-container {
        min-width: 35px;
    }
}

@media (min-width: 800px) {

    .menu-container {
        margin-top: 15px !important;
        min-width: 162px !important;

        .dashboard-menu-item {
            height: 58px !important;

            p {
                display: contents !important;
                text-align: center !important;
                font-size: 12px !important;
                color: #0e122d !important;
            }

            svg {
                margin-right: 5px !important;
                width: 8px;
                height: 8px;
            }
        }

        .dashboard-menu-item.active {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 12px !important;
                color: white !important;
            }
        }

        .dashboard-menu-item:hover {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 12px !important;
                color: white !important;
            }
        }
    }
}

@media (min-width: 980px) {
    .menu-container {
        margin-top: 25px !important;
        min-width: 188px !important;

        .dashboard-menu-item {
            height: 68px !important;

            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: #0e122d !important;
            }

            svg {
                margin-right: 8px !important;
                width: 22px !important;
                height: 22px !important;
            }
        }

        .dashboard-menu-item.active {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: white !important;
            }
        }

        .dashboard-menu-item:hover {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: white !important;
            }
        }
    }
}

@media (min-width: 1400px) {
    .menu-container {
        margin-top: 25px !important;
        min-width: 188px !important;

        .dashboard-menu-item {
            margin-top: 15px !important;
            height: 68px !important;

            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: #0e122d !important;
            }

            svg {
                margin-right: 8px !important;
                width: 22px !important;
                height: 22px !important;
            }
        }

        .dashboard-menu-item.active {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: white !important;
            }
        }

        .dashboard-menu-item:hover {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: white !important;
            }
        }
    }
}

@media (min-width: 1700px) {
    .menu-container {
        margin-top: 40px !important;
        min-width: 188px !important;

        .dashboard-menu-item {
            margin-top: 15px !important;
            height: 68px !important;

            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: #0e122d !important;
                margin-top: 4px !important;
            }

            svg {
                margin-right: 8px !important;
                width: 22px !important;
                height: 22px !important;
            }
        }

        .dashboard-menu-item.active {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: white !important;
            }
        }

        .dashboard-menu-item:hover {
            p {
                display: contents !important;
                text-align: center !important;
                font-size: 14px !important;
                color: white !important;
            }
        }
    }
}