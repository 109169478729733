body {
    background-color: #f2f2f2;
    height: 100vh;
    width: 100%;
}

.page_wrap_row {
    display: flex;
    flex-flow: row wrap;
}

p {
    margin-bottom: 0;
}

// nav {
//     position: fixed;
//     width: 100vw;
//     background-color: #060a24;
//     box-sizing: border-box;
//     z-index: 1;
//     font-size: 1vw;

//     svg {
//         margin-left: 2vw;
//         min-width: 5vw;
//         max-width: 5vw;
//     }

//     p {
//         padding: 1vw;
//         margin-right: 2vw;
//     }

//     strong {
//         position: absolute;
//         width: 100%;
//         text-align: center;
//         font-size: 1vw;
//     }

//     .nav-content-wrapper {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         width: 100%;
//         height: 4vw;
//     }

//     .logout-btn-wrapper {
//         display: flex;
//         justify-content: flex-end;
//         background-color: transparent;
//         color: white;
//         border: none;
//         z-index: 1111111111111111111111111111 !important;
//     }

//     @media only screen and (min-width: 620px) {
//         .logout-btn-wrapper {
//             width: 100%;
//             max-width: 167px;
//             display: flex;
//             justify-content: flex-end;
//         }
//     }

//     @media only screen and (max-width: 500px) {
//         #dashboard-title {
//             display: none;
//         }
//     }


//     .logout-btn-wrapper p {
//         transition: 0.2s;
//         cursor: pointer;
//         user-select: none;
//     }

//     .logout-btn-wrapper p:hover {
//         filter: brightness(80%);
//     }
// }

.page-wrapper-dashboard {
    background-color: #f2f2f2;
    color: black;
    width: 100vw;
    overflow-y: scroll;
}

.welcome-wrapper {
    display: flex;
    flex-flow: column wrap;
    align-items: baseline;
    position: absolute;
    margin-top: 4.5vw;
    margin-left: 12.5vw;

    h2 {
        text-align: baseline;
        color: #000;
        font-size: 30px;
        font-weight: 600;
        font-family: Nunito-Regular;
        margin: 5px 0;
    }


    p {
        font-size: 1vw;
        margin: 0;
    }

    span {
        color: #d63230;
    }
}


.package-wrapper {
    margin-top: 10vw;
    width: 100%;
    padding-bottom: 5vw;

    .packages-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 75vh;
    }

    .packages-monthly {
        display: none;
    }

    .packages-inner-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
        justify-content: center;
        place-items: center;
        gap: 15px;
        width: 100%;
        max-width: 925px;
    }

    .package-title {
        display: flex;
        justify-content: center;
        font-size: 25px;
        // margin-bottom: 12px;
    }

    .package-card-red {
        width: 100%;
        max-width: 295px;
        border: 1px solid #DBDBDB;
        border-radius: 5px 5px 20px 20px;
        box-shadow: 0px 0px 10px 0px rgb(153 145 145 / 50%);
        box-sizing: border-box;
        padding: 20px;
        text-align: center;
        min-height: 665px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #E73A3A;


        button {
            color: #FFFFFF;
            background-color: #E73A3A;
            border-style: solid;
            border-width: 3px 3px 3px 3px;
            border-color: #FFFFFF;
            border-radius: 5px 5px 5px 5px;
            padding: 10px 30px 10px 30px;
            font-family: "Poppins", Sans-serif;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            transition: .2s;
        }

        button:hover {
            filter: brightness(80%);
            transition: .2s;
        }

        strong {
            font-size: 22px !important;
            color: #FFFFFF;
        }

        p {
            color: #FFFFFF;
            margin: 10px;
        }

        button {
            width: 100%;
        }

        .price-wrapper {
            margin-top: 5px;

            span {
                font-size: 64px;
                color: #FFFFFF;
                font-family: 'Montserrat', sans-serif;
                font-weight: 800;
            }

            p {
                font-size: 12px;
                color: #FFFFFF;
                margin: 0;
            }
        }

        .package-option-info-wrapper {
            min-height: 355px;

            .package-option-info-inner-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 300px;

                p {
                    margin: 0 !important;
                }
            }
        }

        hr {
            height: 1px;
            background-color: #DDDDDD;
            border: none;
            // max-width: 100px;
        }
    }

    .package-card-hidden {
        opacity: .3;
    }

    .package-card {
        width: 100%;
        max-width: 295px;
        border: 1px solid #DBDBDB;
        border-radius: 5px 5px 20px 20px;
        box-shadow: 0px 0px 10px 0px rgb(153 145 145 / 50%);
        box-sizing: border-box;
        padding: 20px;
        text-align: center;
        min-height: 665px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .downgrade {
            filter: opacity(0.6)
        }

        button {
            color: #1C224D;
            background-color: #FFFFFF;
            border-style: solid;
            border-width: 3px 3px 3px 3px;
            border-color: #1C224D;
            border-radius: 5px 5px 5px 5px;
            padding: 10px 30px 10px 30px;
            font-family: "Poppins", Sans-serif;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            transition: .2s;
        }

        button:hover {
            filter: brightness(80%);
            transition: .2s;
        }

        strong {
            font-size: 22px !important;
            color: #1C224D;
        }

        p {
            color: #747474;
            margin: 10px;
        }

        button {
            width: 100%;
        }

        .price-wrapper {
            margin-top: 5px;

            span {
                font-size: 64px;
                color: #1C224D;
                font-family: 'Montserrat', sans-serif;
                font-weight: 800;
            }

            p {
                font-size: 12px;
                color: #747474;
                margin: 0;
            }
        }

        .package-option-info-wrapper {
            min-height: 355px;

            .package-option-info-inner-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 300px;

                p {
                    margin: 0 !important;
                }
            }
        }

        hr {
            height: 1px;
            background-color: #DDDDDD;
            border: none;
            // max-width: 100px;
        }
    }
}

footer {
    margin-top: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #1C224D;
    bottom: 0;
    width: 100vw;

    .dashboard-footer-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 6vh;
        font-size: .9vw;

        a {
            color: #ffffff;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: 400px) {

    // nav {
    //     flex-wrap: wrap;
    //     padding: 6px;
    // }

    footer {
        text-align: center;
    }
}

@media only screen and (max-width: 360px) {
    // nav {
    //     font-size: 12px;

    //     strong {
    //         margin-left: 5px;
    //     }

    //     p {
    //         margin-left: 5px;
    //     }
    // }
}

.package-switch-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;

    .package-options {
        width: 70px;
    }

    .switch-wrapper {
        min-width: 60px;
    }

    @media only screen and (max-width: 440px) {
        // nav {
        //     padding: 10px;
        // }

        p {
            font-size: 15px;
            width: 50%;
        }
    }
}

.modal-footer {
    display: flex !important;
    justify-content: space-between !important;
}

.panel-footer {
    padding: 15px !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #E73A3A;
}

input:focus+.slider {
    box-shadow: 0 0 1px #E73A3A;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.modal-content {
    color: black !important;
}

.check-wrapper {
    width: 100%;
    min-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    .check-inner-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 900px;
    }

    .check-content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: fit-content;
        // margin-left: 30px;

        p {
            margin-left: 7px;
        }
    }
}

@media screen and (max-width: 820px) {

    .check-inner-wrapper {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-wrap: wrap !important;
    }

    .welcome-wrapper {
        position: relative !important;
        margin-top: 45px !important;
        padding: 0 !important;

        h2 {
            font-size: 3.8vw !important;
            font-weight: 600;
            margin: 5px 0;
        }


        p {
            font-size: 2.5vw !important;
            margin: 0 !important;
        }
    }

    .dashboard-footer-wrapper {
        min-height: 5vh !important;
        font-size: 2.5vw !important;

        a {
            color: #ffffff;
            font-weight: bold;
        }
    }

    .page-wrapper-dashboard {
        margin-left: 20px !important;
    }

    // nav {
    //     padding: 8px;

    //     svg {
    //         min-width: 92px !important;
    //     }

    //     strong {
    //         font-size: 14px !important;
    //     }

    //     p {
    //         font-size: 14px !important;
    //     }
    // }

    @media screen and (max-width: 350px) {
        .check-inner-wrapper {
            display: flex !important;
            justify-content: flex-start !important;
            align-items: center !important;
            flex-wrap: wrap !important;
        }
    }

    .welcome-wrapper {
        margin-left: 80px !important;
    }

    .package-wrapper {
        margin-left: 10px !important;
    }

    .check-content-wrapper {
        margin: 7px !important;
    }

    .packages-inner-wrapper:last-of-type {
        margin-bottom: 40px !important;
    }
}

@media only screen and (max-width: 698px) {
    .welcome-wrapper {
        margin-left: 30px !important;
    }

    .packages-title {
        font-size: 16px !important;
    }

    .package-wrapper {
        margin-top: 10px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (min-width: 700px) {
    .packages-title {
        font-size: 16px !important;
    }

    // nav {
    //     padding: 5px;

    //     svg {
    //         min-width: 92px !important;
    //     }

    //     strong {
    //         font-size: 14px !important;
    //     }

    //     .logout-btn-wrapper {
    //         p {
    //             font-size: 14px !important;
    //         }
    //     }

    //     .nav-content-wrapper {
    //         margin-top: 8px !important;
    //     }
    // }

    .package-wrapper {
        padding: 0 !important;
        margin-top: 23px !important;
        text-align: center !important;
    }

    .welcome-wrapper {
        margin-top: 50px !important;
        margin-left: 30px !important;

        h2 {
            font-size: 24px !important;
        }

        p {
            font-size: 12px !important;
        }
    }
}

@media only screen and (min-width: 800px) {
    .welcome-wrapper {
        margin-left: 155px !important;

        h2 {
            font-size: 24px !important;
        }

        p {
            font-size: 12px !important;
        }
    }

    .package-wrapper {
        margin-left: 70px !important;
        padding-left: 55px !important;
        padding-right: 55px !important;
    }
}

@media only screen and (min-width: 819px) {
    .welcome-wrapper {
        margin-left: 170px !important;
    }

    .package-wrapper {
        margin-left: 80px !important;
        margin-top: 125px !important;
        padding-left: 95px !important;
        padding-right: 95px !important;
    }
}

@media only screen and (min-width: 970px) {
    .package-wrapper {
        margin-left: 215px !important;
        width: 80% !important;
        text-align: center !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .welcome-wrapper {
        margin-top: 65px !important;
        margin-left: 210px !important;
    }

    .check-wrapper {
        padding-right: 25px !important;
    }

    // nav {
    //     padding: 5px !important;
    // }
}

@media only screen and (min-width: 1200px) {

    // nav {
    //     padding: 0 !important;

    //     .nav-content-wrapper {
    //         margin-top: 5px !important;
    //     }
    // }

    .package-wrapper {
        margin-left: 200px !important;
        margin-top: 140px !important;
        width: 80% !important;
        text-align: center !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        padding-bottom: 70px !important;
    }

    .welcome-wrapper {
        margin-left: 210px !important;

        h2 {
            font-size: 28px !important;
        }

        p {
            font-size: 14px !important;
        }
    }

    .check-wrapper {
        padding-right: 25px !important;
    }
}

@media only screen and (min-width: 1400px) {
    // nav {
    //     padding: 0px !important;

    //     .nav-content-wrapper {
    //         margin-top: 0px !important;
    //     }
    // }

    .package-wrapper {
        margin-left: 195px !important;
    }

    .welcome-wrapper {
        margin-top: 60px !important;
        margin-left: 200px !important;

        h2 {
            font-size: 32px !important;
        }

        p {
            font-size: 14px !important
        }
    }
}

@media only screen and (min-width: 1650px) {

    // nav {
    //     svg {
    //         width: 128px !important;
    //     }

    //     strong {
    //         font-size: 16px !important;
    //     }

    //     .logout-btn-wrapper {
    //         p {
    //             font-size: 18px !important;
    //             margin-bottom: 4px !important;
    //         }
    //     }
    // }

    .package-wrapper {
        margin-left: 200px !important;
    }

    .welcome-wrapper {
        margin-top: 70px !important;
        margin-left: 200px !important;
    }
}